import { Component, OnInit, HostListener } from '@angular/core';
import { SessionService } from '../../services/shared/session.service';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ScreenSizeService } from '../../services/shared/screen-size.service';

@Component({
  selector: 'app-log-badge',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './log-badge.component.html',
  styleUrl: './log-badge.component.css'
})
export class LogBadgeComponent implements OnInit{

  isMobile: boolean;

  constructor(protected userLogado: SessionService, 
    private router: Router, 
    private logedService: SessionService,
    private screenSizeService: ScreenSizeService){  }

  ngOnInit(): void {
    let mobile = window?.innerWidth < 768; 
     this.screenSizeService.setMobile(mobile)
     this.isMobile = this.screenSizeService.getMobile()
  }

  logOff(){
    localStorage.removeItem('AccessToken')
    localStorage.removeItem('jwtToken')
    this.userLogado.setToken(null)
    this.logedService.primeiroAcesso = true
    this.router.navigate(['/home'])
  }

  isUserOrBackoffice(): boolean {
    return this.userLogado.getToken()?.role == "PERSON";
  }

  isInstitution(): boolean {
    return this.userLogado.getToken()?.profile === 'Instituição Comum';
  }

  isLoggedIn(): boolean {
    return !!this.userLogado.getToken();
  }

  @HostListener('window:resize', ['$event'])
   onResize(event: Event) {
     this.checkScreenSize();
   }
   
   private checkScreenSize() {
     let mobile = window?.innerWidth < 768; 
     this.screenSizeService.setMobile(mobile)
     this.isMobile = this.screenSizeService.getMobile()
   }

}
