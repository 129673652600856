<div class="dropdown" style="margin-right: 30px;" style="cursor: pointer;">
    <div class="button-container" data-bs-toggle="dropdown" style="display: flex;">
        <i class="bi bi-person-circle" [ngClass]="{'icon-mobile': isMobile, 'icon-desktop': !isMobile }"></i>
        <p *ngIf="!isMobile" class="gradient-button"> {{userLogado.getToken() ?
            userLogado.getToken().name : "Acesse sua conta" }}</p>
        <!-- <button class="button-access-account"> Acesse sua conta </button> -->
    </div>
    <ul class="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuButton">
        <li *ngIf="isUserOrBackoffice()">
            <a class="dropdown-item" routerLink="/home-usuario">Home Usuário</a>
            <a class="dropdown-item" routerLink="/pessoa/atualizar">Alterar Dados</a>
        </li>
        <li *ngIf="isInstitution()">
            <a class="dropdown-item" routerLink="/entidade-social/home-entidade">Home Entidade</a>
        </li>
        <li *ngIf="isLoggedIn()">
            <a class="dropdown-item" (click)="logOff()">Deslogar</a>
        </li>
        <li *ngIf="!isLoggedIn()">
            <a class="dropdown-item dropdown-login" routerLink="/login">Logar</a>
        </li>
    </ul>
</div>