import { Component, HostListener, Renderer2, TemplateRef, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, Router, RouterModule } from '@angular/router';
import { LogBadgeComponent } from '../../log-badge/log-badge.component';
import { SessionService } from '../../../services/shared/session.service';
import { HomePessoaFisicaComponent } from '../../../core/components/pessoa/home-pessoa-fisica/home-pessoa-fisica/home-pessoa-fisica.component';
import { PessoaService } from '../../../services/integration/pessoa.service';
import { AlertComponent } from '../../alert/alert.component';
import { AlertService } from '../../../services/alerts/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar',
  imports: [CommonModule, RouterModule, LogBadgeComponent, AlertComponent],
  templateUrl: './navbar.component.html',
  standalone: true,
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {
  isNavbarTransparent = false;

  constructor(private renderer: Renderer2,
    protected userLogado: SessionService,
    protected userService: PessoaService,
    protected alertService: AlertService,
    protected router: Router) {

      this.router.events.subscribe(() => {
        this.collapseNavbar();
      });
  
      this.renderer.listen('document', 'click', (event: Event) => {
        const navbarElement = document.querySelector('.navbar-collapse');
        if (navbarElement && navbarElement.classList.contains('show')) {
          const targetElement = event.target as HTMLElement;
          const togglerElement = document.querySelector('.navbar-toggler');
  
          if (togglerElement && !togglerElement.contains(targetElement) && !navbarElement.contains(targetElement)) {
            this.collapseNavbar();
          }
        }
      });

  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (offset > 0) {
      this.renderer.addClass(document.querySelector('.navbar'), 'navbar-transparent');
      this.isNavbarTransparent = true;
    } else {
      this.renderer.removeClass(document.querySelector('.navbar'), 'navbar-transparent');
      this.isNavbarTransparent = false;
    }
  }

  deletar() {
    this.userService.delete(this.userLogado.getToken().id).subscribe({
      next: (response) => {
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('jwtToken')
        this.userLogado.setToken(null);
        this.alertService.addAlert("Usuário descadastrado", 'success');
        this.router.navigate(['/login']);
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.alertService.addAlert(err.error.exception, 'danger!');
      }
    });
  }


  private modalService = inject(NgbModal);
  closeResult = '';

  open(content: TemplateRef<any>) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result: any) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason: any) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }


  @ViewChild('deleteContent') modalContent: TemplateRef<any>;

  collapseNavbar() {
    const navbarElement = document.querySelector('.navbar-collapse');
    if (navbarElement && navbarElement.classList.contains('show')) {
      this.renderer.removeClass(navbarElement, 'show');
    }
  }

}
